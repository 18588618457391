import React, { useEffect, useState } from "react"
import CategoryTitle from "./categorytitle"
import canvas1 from "../../images/canvas1.png"
import canvas2 from "../../images/canvas2.png"
import NewsItem from "./news_item"
import VisibilitySensor from 'react-visibility-sensor';


const News = () => {
    const [lang, setLang] = useState()
    useEffect(() => {
        setLang(sessionStorage.getItem('lang'))
    }, [])
    const translateNews = (lang) => {
         if (lang === 'ru') {
            return (
                <div className="list w100 left mt25">
                    <NewsItem to="/sermobile-pro" title="serMobilePRO v4.5" description="Добавлена опция арабского языка" />
                    <NewsItem to="/ser-b2c" title="SER-B2C Модуль Магазина" description="Теперь вы можете сделать свои продажи в магазине очень легко" />
                    <NewsItem to="/sersync-pro" title="SMART Интеграция" description="Интеграция для смарт-бухгалтерской программы завершена" />
                    <NewsItem to="/serfoto" title="serFoto Master Template" description="Ваш контент теперь автоматически формируется в соответствии с вашим количеством вариантов продукта" />
                </div>
                )
        } else if (lang === 'en') {
            return (
                <div className="list w100 left mt25">
                    <NewsItem to="/sermobile-pro" title="serMobilePRO v4.5" description="Arabic language option added" />
                    <NewsItem to="/ser-b2c" title="SER-B2C Store Module" description="You can now make your store sales very easily" />
                    <NewsItem to="/sersync-pro" title="SMART Integration" description="Integration is complete for the smart accounting program" />
                    <NewsItem to="/serfoto" title="serFoto Master Template" description="Based on the number of product variants, your content is now created automatically." />
                </div>
                )
        }else{
            return (
                <div className="list w100 left mt25">
                    <NewsItem to="/sermobile-pro" title="serMobilePRO v4.5" description="Arapça dil seçeneği eklendi" />
                    <NewsItem to="/ser-b2c" title="SER-B2C Mağaza Modülü" description="Artık mağaza satışlarınızı çok kolay şekilde yapabilirsiniz" />
                    <NewsItem to="/sersync-pro" title="SMART Entegrasyonu" description="Smart muhasebe programı için entegrasyon tamamlandı" />
                    <NewsItem to="/serfoto" title="serFoto Master Template" description="Ürün varyant sayınıza göre içeriğiniz artık otomatik oluşuyor" />
                </div>
                )
        }
    }
    const translateUp = (lang) => {
         if (lang === 'ru') {
            return "Последние Обновления"
        } else if (lang === 'en') {
            return "Latest Updates"
        }else {
            return "Son Güncellemeler"
        }
    }
    const translateBlog = (lang) => {
         if (lang === 'ru') {
            return "Будьте Доступны На Всех Платформах"
        } else if (lang === 'en') {
            return "BLOG"
        }else {
            return "BLOG"
        } 
    }
    const translateCategory = (lang) => {
        if (lang === 'ru') {
            return <CategoryTitle title="ОСТАВАЙТЕСЬ В КУРСЕ" subTitle="Что Происходит?" description="Что Происходит?" />
        } else if (lang === 'en') {
            return <CategoryTitle title="FOLLOW" subTitle="What's Happening Inside?" description="What's Happening Inside?" />
        }else{
            return <CategoryTitle title="TAKİPTE KALIN" subTitle="İçeride Neler Oluyor?" description="İçeride Neler Oluyor?" />
        }
    }
    return (
        <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) =>
                <div className={'item w100 left owh mb15 item5 pos-r b-white col-xs-ha scrollhide windowh ' + (isVisible ? 'scrollvisible' : 'scrollhide')}>
                    {translateCategory(lang)}
                    <div className="itemcontent w100 left scrollhide" style={{ height: "74%" }}>
                        <div className="container pos-r">
                            <div className="motto w100 left t-center fs18 lh18 colored3 op8 col-xs-mt15" style={{ marginBottom: "5%" }}>
                                {translateBlog(lang)}
                            </div>
                        </div>
                        <div className="w100 h60h left col-xs-ha col-xs-pl15 col-xs-pr15">
                            <div className="w50 h100 left pos-r leftside col-xs-w90 canvasarea col-xs-ma col-xs-fn col-sm-w50">
                                <img className="img-responsive h100 pos-a rtanimate4" style={{ right: "15%", top: "15%" }} alt="Mobil" src={canvas1}></img>
                                <img className="img-responsive h100 pos-a tilt" style={{ right: "-2%" }} alt="Mobil" src={canvas2}></img>
                            </div>
                            <div className="w30 right h100 center-h col-xs-w100 col-xs-mr0 col-xs-pl15 col-xs-pr15 col-xs-ha col-xs-mt55 col-sm-w50 col-sm-mr0" style={{ marginRight: "10%" }}>
                                <div className="center-content w100 left pl15 pr15 news">
                                    <h3 className="head w100 left fw6">
                                        {translateUp(lang)}
                                    </h3>
                                    <div className="clear"></div>
                                    {translateNews(lang)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </VisibilitySensor>
    )

}

export default News
