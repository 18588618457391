import React from "react"
import { Link } from "gatsby"

const NewsItem = (props) => {
    return (
        <div className="item bn on bsn">
            <Link className="box w100 left b-white p15 flex bn on bsn col-xs-pl0" to={props.to}>
                <div className="visual wi left mr10">
                    <i className="icon icon-serenay fs20 lh20 colored3 op8 p15 b-ra5 left col-xs-pl0"></i>
                </div>
                <div className="title w100 left fw5 fs16">
                    {props.title}
                    <article className="w100 left mt5 colored3 fw4">
                        {props.description}
                    </article>
                </div>
            </Link>
        </div>
    )
}

export default NewsItem