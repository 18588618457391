import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import HeaderBanner from "./header_jumbotron"
import Img from "gatsby-image"
import IndexStyles from "./index.module.css"

const Banner = ({ siteTitle }) => {
	const [lang, setLang] = useState()
	useEffect(() => {
		setLang(sessionStorage.getItem('lang'))
	}, [])
	const data = useStaticQuery(graphql`
	query {
		logo: file(relativePath: { eq: "logo.png" }) {
			childImageSharp {
			  fixed(height: 25) {
				...GatsbyImageSharpFixed
			  }
			}
		  }
	}
	`)

	// ============= diller session storega göre atama yapılıyor (Banner anasayfa) =========================
	const translate = (lang) => {
		if (lang === 'ru') {
			return (
				<div className="flex w100 left h100 col-xs-block">
					<HeaderBanner title="Наши продукты" description="Просмотр всех наших продуктов" icon="icon-code2" linkTo="/продукты/" />
					<HeaderBanner title="Наши решения" description="Конкретные Проекты По Требованию Вашей Компании" icon="icon-glass-face" linkTo="/решения/" />
					<HeaderBanner title="Связь" description="" classAdd="col-xs-w100" icon="icon-customer1" linkTo="/связь/" />
				</div>)
		} else if (lang === 'en') {
			return (
				<div className="flex w100 left h100 col-xs-block">
					<HeaderBanner title="Products" description="View all our products" icon="icon-code2" linkTo="/products/" />
					<HeaderBanner title="Solutions" description="Special Projects for Your Company's Needs" icon="icon-glass-face" linkTo="/solutions/" />
					<HeaderBanner title="Contact" description="" classAdd="col-xs-w100" icon="icon-customer1" linkTo="/contact/" />
				</div>)
		}else {
			return (
				<div className="flex w100 left h100 col-xs-block">
					<HeaderBanner title="Ürünlerimiz" description="Tüm ürünlerimizi görüntüleyin" icon="icon-code2" linkTo="/urunler/" />
					<HeaderBanner title="Çözümlerimiz" description="Firmanızın İhtiyacına Özel Projelendirmeler" icon="icon-glass-face" linkTo="/cozumler/" />
					<HeaderBanner title="İletişim" description="" classAdd="col-xs-w100" icon="icon-customer1" linkTo="/iletisim/" />
				</div>)
		}
	}
	// ============= diller session storega göre atama yapılıyor (Banner anasayfa) END =========================
	return (
		<section id="banner" className="w100 left h95h b-white bc1 center-h pos-r mainPage">
			<div className="center-content w100 left col-xs-mb0" style={{ height: "60vh" }}>
				<div className="container-fluid w80 h100 col-xs-w100">
					{/* Mobil Visible */}
					<div className="w100 left t-left col-sm-mt65 col-xs-mt0 col-xs-mb15 col-xs-none">
						<Link className="w100 left t-center" to="/">
							<Img fixed={data.logo.childImageSharp.fixed} alt="Serenay Yazılım" title="Serenay Yazılım" fadeIn={false} />
						</Link>
					</div>

					{/* Mobil Visible End */}
					<div className="colored3 w100 left t-center fs18 lh18 op6 col-xs-mb25">
						<h1 className={IndexStyles.visuallyhidden}>Serenay Yazılım</h1>
					</div>
					{translate(lang)}
				</div>
			</div>
		</section>
	)
}


export default Banner
