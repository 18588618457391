import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import CategoryTitle from "./categorytitle"
import VisibilitySensor from 'react-visibility-sensor';

const ECommerce = () => {
    const [lang, setLang] = useState()
    useEffect(() => {
        setLang(sessionStorage.getItem('lang'))
    }, [])
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "b2b.png" }) {
        childImageSharp {
          fluid(maxWidth: 1140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    //============= diller session storega göre atama yapılıyor (anasayfa) ================
    const translateCat = (lang) => {
         if (lang === 'ru') {
            return <CategoryTitle title="SER-B2B" subTitle="Программное Обеспечение Электронной Коммерции" description="Программное Обеспечение Электронной Коммерции" />
        } else if (lang === 'en') {
            return <CategoryTitle title="SER-B2B" subTitle="E-Commerce Software" description="E-Commerce Software" />
        }else {
            return <CategoryTitle title="SER-B2B" subTitle="E-Ticaret Yazılımı" description="E-Ticaret Yazılımı" />
        }
    }
    const translateEn = (lang) => {
         if (lang === 'ru') {
            return "Откройте свой самый большой магазин в интернете с нашим отраслевым опытом"
        } else if (lang === 'en') {
            return "Open your biggest store on the internet with our sectoral experience"
        }else {
            return "En Büyük Mağazanızı sektörel deneyimimiz ile internette açın"
        } 
    }
    const translate2008 = (lang) => {
         if (lang === 'ru') {
            return "Инфраструктура, разработанная с 2008 года"
        } else if (lang === 'en') {
            return "Infrastructure developed since 2008"
        } else {
            return "2008'den Beri Geliştirilen Altyapı"
        }
    }
    const translateIhtiyac = (lang) => {
         if (lang === 'ru') {
            return "все, что вам нужно, именно так, как вы хотите."
        } else if (lang === 'en') {
            return "everything you need, just the way you want it."
        }else {
            return "ihtiyacınız olan her şey, tam da istediğiniz gibi."
        }
    }
    const translateTek = (lang) => {
         if(lang === 'ru') {
            return (
                <Link className="left p15 pt6 pb6 bb1 bl1 br1 bt1 bc1 white colored2-bg b-ra3 mt25 col-xs-table xs-center-button" to="/ser-b2b-ru">
                    исследуйте технологию
                </Link>
            )
        } else if (lang === 'en') {
            return (
                <Link className="left p15 pt6 pb6 bb1 bl1 br1 bt1 bc1 white colored2-bg b-ra3 mt25 col-xs-table xs-center-button" to="/ser-b2b-en">
                    discover technology
                </Link>
            )
        }else{
            return (
                <Link className="left p15 pt6 pb6 bb1 bl1 br1 bt1 bc1 white colored2-bg b-ra3 mt25 col-xs-table xs-center-button" to="/ser-b2b-tr">
                    teknolojiyi keşfet
                </Link>
            )
        } 
    }
    //===== diller session storega göre atama yapılıyor (anasayfa) END ================
    return (
        <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) =>
                <div className={'item w100 left owh mb15 bs2 item2 gradient1 col-sm-pt45 windowh col-xs-ha col-xs-pb45 ' + (isVisible ? 'scrollvisible' : 'scrollhide')}>
                    {translateCat(lang)}
                    <div className="itemcontent w100 left scrollhide" style={{ height: "74%" }}>
                        <div className="container pos-r">
                            <div className="motto w100 left t-center fs18 lh18 colored3 op8" style={{ marginBottom: "5%" }}>
                                {translateEn(lang)}
                            </div>
                        </div>
                        <div className="w100 left">
                            <div className="visual w50 left pos-r col-xs-w100 col-xs-ha" style={{ height: "50%" }}>
                                <Img className="w100" imgStyle={{ maxWidth: "100%" }} alt="Mobil" fluid={data.file.childImageSharp.fluid} fadeIn={false} />
                            </div>
                            <div className="info w50 right center-h col-xs-w100 col-xs-ha col-xs-mr0 col-xs-pl15 col-xs-pr15">
                                <div className="center-content w100 left pl30">
                                    <h2 className="head w100 left col-xs-fs20 col-xs-lh20 col-xs-t-center fw6">
                                        {translate2008(lang)}
                                    </h2>
                                    <article className="w100 left colored3 col-xs-t-center col-xs-mb25">
                                        <p className="mb0 fs22 lh22 fw3">
                                            <i className="icon icon-serenay left mr5 fs15 lh25 col-xs-fn"></i>
                                            {translateIhtiyac(lang)}
                                        </p>
                                    </article>
                                   {translateTek(lang)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </VisibilitySensor>
    )
}

export default ECommerce
