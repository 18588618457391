import React, { useEffect, useState } from "react"
import CategoryTitle from "./categorytitle"
import MobileApp from "./mobileapp"
import ECommerce from "./ecommerce"
import SerMobilePro from "./sermobilepro"
import News from "./news"
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from "gatsby"

const Vitrin = ({ siteTitle }) => {
	const [lang, setLang] = useState()
	useEffect(() => {
		setLang(sessionStorage.getItem('lang'))
	}, [])
	const translateTum = (lang) => {
	 	if (lang === 'ru') {
			return (
				<Link className="col-xs-fs18" to="/технологии">
					"Просмотр технологий, которые мы используем"
				</Link>
			)
		} else if (lang === 'en') {
			return (
				<Link className="col-xs-fs18" to="/technologies">
					"View the technologies we use"
				</Link>
			)
		}else {
			return (
				<Link className="col-xs-fs18" to="/teknolojiler">
					"Kullandığımız teknolojileri görüntüleyin"
				</Link>
			)
		}
	}
	const translateMagaza = (lang) => {
		 if (lang === 'ru') {
			return "Предоставление инноваций и решений, которые снова вдохновляют отрасли, - наш самый большой идеал, и мы любим его производить."
		} else if (lang === 'en') {
			return "It is our greatest ideal to provide innovations and solutions that re-inspire the sectors and we love to produce."
		}else {
			return "Sektörlere yeniden ilham veren yenilik ve çözümler sunmak bizim en büyük idealimiz ve biz üretmeyi seviyoruz. "
		} 
	}
	const translateCategory = (lang) => {
		 if (lang === 'ru') {
			return <CategoryTitle title="Таланты" subTitle="Технологии, Которые Мы Используем" description="Технологии, Которые Мы Используем" />
		} else if (lang === 'en') {
			return <CategoryTitle title="Capabilities" subTitle="Technologies We Use" description="Technologies We Use" />
		}else {
			return <CategoryTitle title="Yetenekler" subTitle="Kullandığımız Teknolojiler" description="Kullandığımız Teknolojiler" isDark={true} />
		} 
	}
	return (
		<div id="vitrin" className="w100 left mt1">

			<div className="list w100 left">

				<MobileApp />
				<ECommerce />
				<SerMobilePro />

				<VisibilitySensor partialVisibility={true}>
					{({ isVisible }) =>
						<div className={'item w100 left owh item4 pos-r col-xs-pt45 gradient1 h60h ' + (isVisible ? 'scrollvisible' : 'scrollhide')}>
							{translateCategory(lang)}
							<div className="itemcontent w100 left scrollhide" style={{ height: "74%" }}>
								<div className="container pos-r" style={{ height: "100%" }}>
									<div className="motto w100 left t-center fs18 lh18 white op4 col-xs-mb25" style={{ marginBottom: "3%" }}>
										{translateMagaza(lang)}
										{/* Sektörlere yeniden ilham veren yenilik ve çözümler sunmak bizim en büyük idealimiz ve biz üretmeyi seviyoruz. */}
									</div>
									<div className="talents w100 left t-center fs24 white fw6">
										<div className="container">
											<Link className="col-xs-fs18" to="/teknolojiler">
												{translateTum(lang)}
												{/* Kullandığımız teknolojileri görüntüleyin */}
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				</VisibilitySensor>
				<News />
			</div>
		</div>
	)
}
export default Vitrin
