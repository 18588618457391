import React, { useEffect, useState } from "react"
import CategoryTitle from "./categorytitle"
import VisibilitySensor from 'react-visibility-sensor';

const SerMobilePro = () => {
    const [lang, setLang] = useState()
    useEffect(() => {
        setLang(sessionStorage.getItem('lang'))
    }, [])
    const translateTum = (lang) => {
        if (lang === 'ru') {
            return "Сделайте свой магазин видимым на рабочем столе, телефоне, планшете и во всех магазинах приложений ...Свяжитесь со своими клиентами из любого места."
        } else if (lang === 'en') {
            return "Make Your Store Visible to Desktop, Phone, Tablet and All App Stores ...Reach your customers from anywhere."
        }else{
            return "Mağazanızı Masaüstü, Telefon, Tablet ve Tüm Uygulama Mağazalarında Görünür Kılın ... Müşterilerinize her yerden ulaşın."
        }
    }
    const translateMagaza = (lang) => {
         if (lang === 'ru') {
            return "Будьте Доступны На Всех Платформах"
        } else if (lang === 'en') {
            return "Be Accessible on All Platforms"
        }else {
            return "Tüm Platformlarda Erişilebilir Olun"
        }
    }
    const translateCategory = (lang) => {
        if (lang === 'ru') {
            return <CategoryTitle title="УДОБНЫЙ" subTitle="Проект" description="Проект" />
        } else if (lang === 'en') {
            return <CategoryTitle title="USER FRIENDLY" subTitle="Design" description="Design" />
        }else {
            return <CategoryTitle title="KULLANICI DOSTU" subTitle="Tasarım" description="Tasarım" />
        }
    }
    return (
        <VisibilitySensor partialVisibility={true}>
        {({ isVisible }) =>
        <div className={'item w100 left owh bs2 item3 col-xs-pt45 gradient1 scrollhide windowh ' + (isVisible ? 'scrollvisible' : 'scrollhide')} >
            {translateCategory(lang)}
            <div className="itemcontent w100 left scrollhide" style={{ height: "74%" }}>
                <div className="container pos-r" style={{ height: "100%" }}>
                    <div className="motto w100 left t-center fs18 lh18 colored3 op8" style={{ marginBottom: "3%" }}>
                        {translateTum(lang)}
                    </div>
                    <div className="slogan w100 left t-center fs18 colored3 col-xs-mt45 fw6">
                        <div className="container">
                            <div className="center-w w50 pb25 col-xs-w90">
                                {translateMagaza(lang)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        </VisibilitySensor>
    )
}

export default SerMobilePro
