import React, { useEffect, useState } from "react"
import CategoryTitle from "./categorytitle"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from "gatsby"

const MobileApp = () => {
  const [lang, setLang] = useState()
	useEffect(() => {
		setLang(sessionStorage.getItem('lang'))
	}, [])
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 1140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const translate = (lang) => {
    if (lang === 'ru') {
      return ("Предлагайте различный контент и опыт своим пользователям в разных странах и местах!")
    } else if (lang === 'en') {
      return ("Offer different content and experiences to your users in different countries and locations!")
    } else {
      return ("Farklı ülke ve lokasyondaki kullanıcılarınıza farklı içerikler ve deneyimler sunun!")
    } 

  }
  return (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) =>
        <div id="page1" className={'item w100 left owh mb15 bs2 item1 gradient1 col-sm-pt45 windowh col-xs-ha ' + (isVisible ? 'scrollvisible' : 'scrollhide')}>
          <CategoryTitle title="App Store &amp; Google Play Store &amp; Huawei App Gallery" subTitle="serMobile PRO" description="serMobile PRO" />
          <div className="itemcontent w100 left scrollvisible" style={{ height: "74%" }}>
            <div className="container pos-r" style={{ height: "100%" }}>
              <div className="motto w100 left t-center fs18 lh18 colored3 op8 col-xs-mb45" style={{ marginBottom: "5%" }}>
                {translate(lang)}
              </div>
              <Link to="/sermobile-pro">
                <Img className="w100 col-xs-mb35" imgStyle={{ maxWidth: "100%" }} alt="Mobil" fluid={data.file.childImageSharp.fluid} fadeIn={false} />
              </Link>
            </div>
          </div>
        </div>
      }
    </VisibilitySensor>
  )
}

export default MobileApp
